import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import Points from "./Points";
import StandardFormBlockContainer from "../../../containers/common/forms/StandardFormBlockContainer";
import Screen from "./Screen";
import "./index.scss";

const HomeIntro = (props) => {
  const { isSecondStateCollectEmail, setIsSecondStateCollectEmail, isLoading } =
    props;
  return (
    <section className="PgAT-HI">
      <div className="container">
        <div className="PgAT-HI-row">
          <div className="PgAT-HI-info">
            <h2 className="PgAT-HI__title">
                <span>Start Selling with a Cost-Saving Toolset and Course</span> for Dropshipping, Arbitrage, and Wholesale
            </h2>
            <Points />
            <StandardFormBlockContainer
              pageFormState={isSecondStateCollectEmail}
              updatePageFormState={setIsSecondStateCollectEmail}
              isLoading={isLoading}
              buttonTextFirstState="Try AMZScout For Free!"
              buttonTextSecondState="Try AMZScout For Free!"
              page={PAGES.AMAZON_TOOLKIT}
              customClass="PgAT-HI-emailCollect"
              placeholder="Enter your email to start your FREE TRIAL!"
              isIntro
            />
          </div>
          <Screen />
        </div>
      </div>
    </section>
  );
};

HomeIntro.propTypes = {
  isLoading: PropTypes.bool,
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
};

export default HomeIntro;
